<template>
  <b-list-group-item
    v-bind="$attrs"
    class="asset-item small py-lg-1"
    :data-ref="`asset${asset.id}`"
    @click="$emit('on-asset-click', asset.id)"
  >
    <div v-if="!isMobile && showThumbnail" class="image-container d-flex">
      <img :src="asset.assetModel.thumbnail ? asset.assetModel.thumbnail.url : $emptyImage"/>
    </div>
    <label class="mb-0" style="flex: 1;" :for="`checkbox${asset.id}`">
      <div style="flex: 1;">
        <div class="d-flex" style="display: inline-block">
          <b-form-checkbox
            v-if="canSelect"
            :id="`checkbox${asset.id}`"
            :checked="selectedValues.includes(asset.id)"
            @input="$emit('on-check-item', $event)"
          >
          </b-form-checkbox>
          <AssetDetailsMobile
            v-if="isMobile"
            :asset='asset'
            :include-warranty="includeWarranty"
          />
          <AssetDetails
            v-else
            :asset='asset'
            :include-badges="includeBadges"
            :include-warranty="includeWarranty"
          />
        </div>
      </div>
    </label>
    <div style="margin-left: 1rem">
      <slot></slot>
    </div>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem, BFormCheckbox } from 'bootstrap-vue';
import AssetDetailsMobile from '@/views/assets/AssetDetailsMobile.vue';
import AssetDetails from '@/views/assets/AssetDetails.vue';

export default {
  name: 'SingleAsset',
  inject: ["mq"],
  components: {
    AssetDetails,
    AssetDetailsMobile,
    BListGroupItem,
    BFormCheckbox,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
    canSelect: {
      type: Boolean,
      default: true,
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
    showThumbnail: {
      type: Boolean,
      default: true,
    },
    includeBadges: {
      type: Boolean,
      default: true,
    },
    includeWarranty: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isMobile() {
      return ['xs', 'sm', 'md'].includes(this.mq.current);
    },

    onScreenSizeTrigger() {
      if (!this.isMobile) {
        return 'hover';
      }
      return 'focus';
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  width: 100px; margin-right: 1.25rem; align-items: center;

  img {
    width: 100px; height: auto;
  }
}

.asset-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    background-color: adjust-lightness($primary, 35%);
  }
}
</style>
